var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('Fiscal ID')) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t('Update your fisical ids')))])]), _c('b-button', {
    staticClass: "text-right font-weight-bolder my-2",
    attrs: {
      "size": "sm",
      "variant": "light-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openModal('legal-person-create-fiscal-modal');
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _c('span', [_vm._v(_vm._s(_vm.$t("Add Fiscal ID")))])])], 1), _c('div', {
    staticClass: "card-body detail"
  }, [_c('legal-person-fiscal-table', {
    ref: _vm.tableRef,
    attrs: {
      "legal-person": _vm.legalPerson
    }
  })], 1)])]), _c('LegalPersonCreateFiscalModal', {
    attrs: {
      "existing-form": {},
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries
    },
    on: {
      "refresh": _vm.onRefresh
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }