<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">{{ $t('Fiscal ID') }} </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t('Update your fisical ids')
            }}</span>
          </div>
          <b-button size="sm" class="text-right font-weight-bolder my-2" variant="light-primary"
            @click="openModal('legal-person-create-fiscal-modal')">
            <span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Files/File.svg" />
            </span>
            <span>{{ $t("Add Fiscal ID") }}</span>
          </b-button>
        </div>
        <div class="card-body detail">
          <legal-person-fiscal-table :ref="tableRef" :legal-person="legalPerson"></legal-person-fiscal-table>
        </div>
      </div>
    </div>
    <LegalPersonCreateFiscalModal :existing-form={} :legal-person="legalPerson" :countries="countries"
      @refresh="onRefresh"></LegalPersonCreateFiscalModal>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import LegalPersonFiscalTable from "@/view/components/tables/legal-person/LegalPersonFiscalTable.vue";
import LegalPersonCreateFiscalModal from "@/view/components/modals/legal-person/LegalPersonCreateFiscalModal.vue";
import { mapState } from "vuex";

export default {
  components: {
    LegalPersonFiscalTable,
    LegalPersonCreateFiscalModal,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      loading: false,
      counterparts: [],
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    tableRef() {
      return "legalPersonFiscalTable";
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Fiscal ID") },
    ]);
  },

  methods: {
    onRefresh() {
      this.$refs[this.tableRef].refresh();
    },
    openModal(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },
  },
};
</script>
