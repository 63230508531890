<template>
  <div>
    <datatable ref="datatableRef" :table-props="tableProps" :total="total" :fields="fields" :options.sync="tableOptions"
      :per-page-options="perPageOptions">
      <template #[`cell.country`]="{ item }">
        <country-flag v-if="item.country" :country-iso="item.country"></country-flag>
      </template>

      <template #[`cell.counterpart`]="{ item }">
        <div>{{ item?.counterpart_name }}</div>
      </template>

      <template #[`cell.from_date`]="{ item }">
        <span class="font-weight-bolder text-muted">{{ item.from_date ? DateService.format(item.from_date, dateFormat)
      ??
      "" : "" }}</span>
      </template>

      <template #[`cell.to_date`]="{ item }">
        <span class="font-weight-bolder text-muted">{{ item.to_date ? DateService.format(item.to_date, dateFormat) ?? ""
      :
      "" }}</span>
      </template>

      <template #[`cell.is_active`]="{ item }">
        <b-badge :variant="item.is_active ? 'primary' : 'danger'">{{
      item.is_active ? this.$t("ACTIVE") : this.$t("NOT ACTIVE")
    }}</b-badge>
      </template>

      <template #[`cell.actions`]="{ item }">
        <div class="d-flex">
          <b-button v-b-tooltip.hover :disabled="!item.attachment"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-4" :title="$t('Download attachment')"
            @click="download(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Clip.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-4"
            @click="onEdit(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Design/Edit.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
            @click="remove(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </b-button>
        </div>
      </template>
    </datatable>

    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
      <b-modal ref="editModal" size="xl" hide-footer @hidden="onModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>{{ $t("Edit Fiscal Number") }}</h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2"
                :class="{ 'spinner spinner-light spinner-right': editModal.isSaving }" @click="handleSubmit(update)">
                {{ $t("Submit") }}
              </b-button>
              <b-button @click="closeModal">
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </div>
        </template>
        <legal-person-fiscal-form ref="formComponent" :existing-form="editModal.item"
          @input="onLegalPersonFiscalFormInput"></legal-person-fiscal-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import Datatable from "@/view/components/tables/Datatable.vue";
import LegalPersonFiscalForm from "@/view/components/forms/legal-person/LegalPersonFiscalForm.vue";
import CompanyFiscalService from "@/core/services/company/company-fiscal.service";
import Swal from "sweetalert2";
import DateService from "@/core/services/date.service";
import { mapGetters, mapState } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    LegalPersonFiscalForm,
    Datatable,
    CountryFlag,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    fetchType: {
      type: String,
      default: "all",
    },
    id: {
      type: [Number, String],
      default: -1,
    },
  },

  data() {
    return {
      DateService,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true, class: "align-middle" },
        {
          label: this.$t("Fiscal Code"),
          key: "fiscalid",
          sortable: true,
          class: ["align-middle", "font-weight-bolder"],
        },
        {
          label: this.$t("From Date"),
          key: "from_date",
          sortable: true,
          class: ["align-middle"],
        },
        {
          label: this.$t("To Date"),
          key: "to_date",
          sortable: true,
          class: ["align-middle"],
        },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      editModal: {
        item: null,
        isSaving: false,
        apiErrors: null,
      },
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
      };
    },
  },
  watch: {
    "editModal.apiErrors": {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          country: [...(val?.country ?? [])],
          fiscalid: [...(val?.fiscalid ?? [])],
          from_date: [...(val?.from_date ?? [])],
          to_date: [...(val?.to_date ?? [])],
          is_active: [...(val?.is_active ?? [])],
          attachment_tus: [...(val?.attachment_tus ?? [])],
          counterpart: [...(val?.counterpart ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },


  methods: {
    itemProvider(ctx, callback) {
      if (this.fetchType === "all") {
        CompanyFiscalService.getAll(this.legalPerson.id, {
          perPage: ctx.perPage,
          page: ctx.currentPage,
          sortBy: ctx.sortBy || "id",
          search: ctx.filter,
          sortDesc: ctx.sortDesc,
          fields: 'id,country,counterpart_name,from_date,to_date,is_active,attachment,fiscalid'
        }).then((res) => {
          this.total = res.count;
          callback(res.results);
        }).catch(() => callback([]));
      } else {
        CompanyFiscalService.getAll(this.id, {
          fields: 'id,country,counterpart_name,from_date,to_date,is_active,attachment,fiscalid'
        }).then((res) => {
          const result = [res];
          this.total = result.length;
          callback(result);
        }).catch(() => callback([]));
      }
      return null;
    },

    onEdit(item) {
      const modal = this.$refs.editModal;
      this.editModal.item = { ...item };
      modal.show();
    },

    closeModal() {
      const modal = this.$refs.editModal;
      modal.hide();
    },

    onModalHidden() {
      this.editModal.apiErrors = null;
      this.editModal.item = null;
    },

    async update() {
      this.$refs.formComponent.updateForm();
      this.editModal.isSaving = true;
      try {
        await CompanyFiscalService.update(this.editModal.item, this.editModal.item.id);
        this.refresh();
        this.closeModal();
        let title = this.$t("Document Updated")
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (typeof err == "object") {
          this.editModal.apiErrors = err;
          return;
        }
        backendErrorSwal(err);
      } finally {
        this.editModal.isSaving = false;
      }
    },

    remove(item) {
      Swal.fire({
        title: this.$t("Delete").toUpperCase(),
        text: this.$t("Are you sure you want to delete this?"),
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(async (res) => {
        if (res.isConfirmed) {
          try {
            await CompanyFiscalService.delete(item.id);
            Swal.fire(this.$t("Success"), this.$t("Document deleted"), "success");
            this.refresh();
          } catch (err) {
            backendErrorSwal(err);
          }
        }
      });
    },

    async download(item) {
      try {
        const res = await CompanyFiscalService.getOne(item.id);
        window.open(res.attachment, "_blank");
      } catch (err) {
        console.log(err);
        backendErrorSwal(err);
      }
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    getExtendedCountry(iso) {
      const country = this.countries.find((el) => el.iso == iso);
      if (!country) {
        return iso;
      }
      return country.name;
    },

    onLegalPersonFiscalFormInput(form) {
      this.editModal.item = form;
    },
  },
};
</script>
