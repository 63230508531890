var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.country",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.country ? _c('country-flag', {
          attrs: {
            "country-iso": item.country
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell.counterpart",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.counterpart_name))])];
      }
    }, {
      key: "cell.from_date",
      fn: function fn(_ref3) {
        var _vm$DateService$forma;
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(item.from_date ? (_vm$DateService$forma = _vm.DateService.format(item.from_date, _vm.dateFormat)) !== null && _vm$DateService$forma !== void 0 ? _vm$DateService$forma : "" : ""))])];
      }
    }, {
      key: "cell.to_date",
      fn: function fn(_ref4) {
        var _vm$DateService$forma2;
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(item.to_date ? (_vm$DateService$forma2 = _vm.DateService.format(item.to_date, _vm.dateFormat)) !== null && _vm$DateService$forma2 !== void 0 ? _vm$DateService$forma2 : "" : ""))])];
      }
    }, {
      key: "cell.is_active",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('b-badge', {
          attrs: {
            "variant": item.is_active ? 'primary' : 'danger'
          }
        }, [_vm._v(_vm._s(item.is_active ? this.$t("ACTIVE") : this.$t("NOT ACTIVE")))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-4",
          attrs: {
            "disabled": !item.attachment,
            "title": _vm.$t('Download attachment')
          },
          on: {
            "click": function click($event) {
              return _vm.download(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-4",
          attrs: {
            "title": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.onEdit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  }), _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var handleSubmit = _ref7.handleSubmit;
        return [_c('b-modal', {
          ref: "editModal",
          attrs: {
            "size": "xl",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
              }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Edit Fiscal Number")))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.editModal.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.update);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('legal-person-fiscal-form', {
          ref: "formComponent",
          attrs: {
            "existing-form": _vm.editModal.item
          },
          on: {
            "input": _vm.onLegalPersonFiscalFormInput
          }
        })], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }